<template>
  <div class="partenaire-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Liste des Partenaires</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <PartenaireFilterForm
              ref="filterForm"
              v-model="filters"
              slot="filter"
            />
          </DataFilter>

          <br />

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
            }"
          >
            <template v-slot:item.publie="{ item }">
              <v-simple-checkbox
                v-model="item.publie"
                disabled
              ></v-simple-checkbox>
            </template>
            <template
              slot="item.mediaObjectImage"
              slot-scope="{ item }"
              v-if="item.mediaObjectImage"
            >
              <v-img
                v-if="item.mediaObjectImage && isImageExtension(item.mediaObjectImage.contentUrl)"
                contain
                max-height="75px"
                max-width="10vw"
                :src="getFileUrl(item.mediaObjectImage.contentUrl)"
              />
              <template v-else>{{ item.contentUrl.toUpperCase() }}</template>
            </template>

            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import PartenaireFilterForm from "../../components/partenaire/Filter";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import { isImageExtension } from "../../utils/filesType";
import { ENTRYPOINT } from "../../config/entrypoint";

export default {
  name: "PartenaireList",
  servicePrefix: "Partenaire",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    PartenaireFilterForm,
    DataFilter,
  },
  data() {
    return {
      headers: [
        { text: "image", value: "mediaObjectImage" },
        { text: "Nom", value: "nom" },
        // { text: 'siteWeb', value: 'siteWeb' },
        // { text: 'facebook', value: 'facebook' },
        { text: "Catégories", value: "categorie[nom]" },
        { text: "Logo", value: "image" },
        // { text: 'description', value: 'description' },
        { text: "Publié", value: "publie" },
        { text: "Edition", value: "evenement[nom]" },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
      ],
      selected: [],
    };
  },
  computed: {
    ...mapGetters("partenaire", {
      items: "list",
    }),
    ...mapFields("partenaire", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("partenaire", {
      getPage: "fetchAll",
      deleteItem: "del",
    }),
    isImageExtension,
    getFileUrl(path) {
      return ENTRYPOINT + path;
    },
  },
};
</script>
